import "../css/header.css";
import React, { useContext } from "react";
import { useNavigate, Link } from "react-router-dom";
import { CartContext } from "../Component/CartContext";

function Header() {
  const navigate = useNavigate();
  const handleAddToCart = () => {
    navigate("/cartpage");
  };
  const { cartItems, getProductById } = useContext(CartContext);

  return (
    <div className="header-container">
      <div className="allDiv">
        <div className="headerDiv">
          <div className="leftImg">
            <img alt="" src="/head-foot/head.png" />
          </div>
          <div className="headerMid">
            <nav className="navigation">
              <ul className=" main-nav">
                <li className="menu-item">
                  <a href="/" className="homeToA">
                    HOME
                  </a>
                </li>
                <li className="menu-item">
                  <a href="/shop" className="homeToA">
                    SHOP
                  </a>
                </li>
                <li className="menu-item">
                  <a href="/contact">Contact Us</a>
                </li>
              </ul>
            </nav>
          </div>
          <div className="rightT">
            <p className="cartItemCount">{cartItems.length}</p>
            <Link to="/cartpage" className="to-add">
              <img alt="" src="/head-foot/head-cart.png" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
