import React from "react";
import "../css/footer.css";

function Footer() {
  return (
    <>
      <div className="bottom-container">
        <div className="botLeft">
          <div className="comImfo">
            <ul>
              <h3 className="comImfoH3">Site Links</h3>
              <li>
                <a href="/">Home</a>
              </li>
              <li>
                <a href="/shop">Shop Online</a>
              </li>
              <li>
                <a href="/contact">Contact Us</a>
              </li>
              <li>
                <a href="/privacy">Privacy Policy.</a>
              </li>
              <li>
                <a href="/terms">Terms & Conditions</a>
              </li>
            </ul>
          </div>

          <div className="comImfo12">
            <h3 className="comImfoH3">Social Media</h3>
            <div className="com-img">
              <img alt="" src="/fruit/foot1.jpg" />
              <img alt="" src="/fruit/foot2.jpg" />
              <img alt="" src="/fruit/foot3.jpg" />
              <img alt="" src="/fruit/foot4.jpg" />
            </div>
            <div className="icon-com">
              <img alt="" src="/head-foot/icon-fb.png" />
              <img alt="" src="/head-foot/icon-instagram.png" />
              <img alt="" src="/head-foot/icon-twitter.png" />
            </div>
          </div>
        </div>
        <div className="pre-footer-newsletter">
          <div className="pre-footer">
            <h3>
              <strong>Newsletter</strong>
            </h3>
            <div className="foot-content">
              <form className="form-subscribe">
                <p className="form-label">
                  Sign up to our newsletter and be the first to know about
                  Special Offers, Competitions, New Products and News from The
                  Farm.
                </p>
                <div className="fields">
                  <div className="field-newsletter">
                    <input type="text" placeholder="Enter your email address" />
                  </div>
                  <div class="actions">
                    <button
                      class="action subscribe primary"
                      title="Subscribe"
                      type="submit"
                      aria-label="Subscribe"
                    >
                      Subscribe
                    </button>
                  </div>
                  <div class="privacy">
                    <label for="privacy-label">
                      <p>
                        I consent to British Frozen Fruits using my email in
                        accordance with their&nbsp;
                        <a href="/privacy" className="p-a">
                          Privacy Policy
                        </a>
                        .
                      </p>
                    </label>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="legal">
          <div className="footLeftBot">
            <div className="legalLeft">
              Copyright © 2024 Windmill Hill Fruits Ltd. All rights reserved.{" "}
            </div>
            <div className="footer_bar_seperator">Website by Clickingmad</div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
